import React, { Component } from "react";
import render from "react-dom";
import { Manager, Reference, Popper } from "react-popper";
import "../App.css";
import imageOne from "../images/project/project-image01.png";

class Home extends Component {
  render() {
    return (
      <>
        <nav className="navbar navbar-expand-sm navbar-light">
          <div className="container">
            <a className="navbar-brand" href="index.html">
              Blintz Pizza
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <a href="#about" className="nav-link">
                    <span data-hover="Menu">Menu</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#project" className="nav-link">
                    <span data-hover="Deals">Deals</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#contact" className="nav-link">
                    <span data-hover="Blintz Pizza Story">
                      Blintz Pizza Story
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <section
          className="about full-screen d-lg-flex justify-content-center align-items-center"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                <div className="about-text">
                  <h1 className="animated animated-text">
                    <span className="mr-2">We Deliver Fresh Pizza </span>
                    <div className="animated-info">
                      <span className="animated-item2">Order Now</span>
                      {/* <span className="animated-item">Tasty Pizza</span> */}
                      {/* <span className="animated-item">Online Ordering</span> */}
                    </div>
                  </h1>

                  <p>
                    We make fresh dough daily to give our customers next level
                    experience.
                  </p>

                  <div className="custom-btn-group mt-4">
                    <a
                      href="https://apple.co/34Zm9pP"
                      className="btn mr-lg-2 custom-btn"
                    >
                      <i className="uil uil-file-alt"></i> Download iPhone
                    </a>
                    <a
                      href="https://bit.ly/37a8TBl"
                      className="btn mr-lg-2 custom-btn"
                    >
                      <i className="uil uil-file-alt"></i> Download Android
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-12 col-12">
                <div className="about-image svg">
                  <img
                    src={require("../images/undraw/pizzaImg.jpg")}
                    className="img-fluid"
                    alt="svg image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="delivery full-screen d-lg-flex justify-content-center align-items-center"
          id="delivery"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                <div class="col-lg-8 mx-auto">
                  <h2>Environment Friendly Delivery</h2>
                  <p>
                    We use bicycles to deliver your pizza because it's
                    environmentally friendly and best for local areas.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-12">
                <div className="about-image img">
                  <img
                    src={require("../images/project/man-delivery.png")}
                    className="img-fluid"
                    alt="svg image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="delivery full-screen d-lg-flex justify-content-center align-items-center"
          id="delivery"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                <div className="about-image img">
                  <img
                    src={require("../images/project/pizzaDouge.jpg")}
                    className="img-fluid"
                    alt="svg image"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-12 align-items-left">
                <div class="col-lg-8 mx-auto">
                  <h2>We use fresh pizza douge</h2>
                  <p>
                    We make fresh pizza douge at store to give our customer good
                    quality pizza.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="project py-5 bg-dark" id="project">
          <div class="container">
            <div class="row">
              <div class="col-lg-12  mx-auto col-12 column">
                <div class="col-lg-12 text-center mx-auto">
                  <h1
                    style={{
                      color: "white",
                      fontSize: 50,
                      fontFamily: "FascinateInline-Regular",
                    }}
                  >
                    HOT & Fresh Pizza
                  </h1>
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                    We delivers hot and fresh pizza at your doorstep.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="footer py-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-12">
                <p class="copyright-text text-center">
                  Copyright &copy; 2019 Blintz Pizza. All rights reserved
                </p>
                <p class="copyright-text text-center">MON-SUN 11 AM-11 PM</p>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Home;
