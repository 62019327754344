import React, { Component } from "react";
// import "../Login/Login.css";

class UserProfile extends Component {
  state = {
    isUserLogin: false,
    profile: ""
  };
  componentDidMount() {
    // console.log("lena", this.props.location.state);
    this.setState(
      {
        profile: this.props.location.state.data.data
      },
      () => console.log(this.state)
    );
  }

  render() {
    // console.log("lol ka data", this.state);
    let { first_name, last_name, avatar } = this.state.profile;
    return (
      <React.Fragment>
        <div className="container" id="container">
          <div className="form-container sign-in-container">
            <img alt="profileImage" className="profileImageView" src={avatar} />
            <h3>
              {first_name} {last_name}
            </h3>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserProfile;
