import React, { Component } from "react";
// import "bootstrap/dist/css/bootstrap.css";
import BrowserRouter, { Link } from "react-browser-router";

class SearchProperty extends Component {
  state = { isUserLogin: true };

  constructor() {
    super();
    document.title = "Search property";
  }

  render() {
    // if (this.state.isUserLogin) {
    //   return <div>Please login to view this page</div>;
    // }
    return (
      <div>
        <ul class="nav">
          <li class="nav-item">
            <Link class="nav-link" to="/forgot-password">
              Roommates
            </Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link" to="/forgot-password">
              Chat
            </Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link" to="/search-property">
              Search
            </Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link" to="/forgot-password">
              Profile
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class="nav-link"
              to="/order-form"
              aria-disabled="true"
              tabindex="-1"
            >
              Payment
            </Link>
            {/* <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
            >
              Payment
            </a> */}
          </li>
        </ul>
      </div>
    );
  }
}

export default SearchProperty;
