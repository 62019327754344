import React, { Component } from "react";
import "./Login/Login.css";
import BrowserRouter, { Link } from "react-browser-router";

class PageNotFound extends Component {
  render() {
    return (
      <div>
        {/* <h2 className="headingBlack">Page you are looking not found.</h2> */}
        <div className="jumbotron">
          <h1 className="display-4">SORRY!</h1>
          <p className="lead">Page you are looking not found.</p>
          <hr className="my-4" />
          <p>
            It uses utility classes for typography and spacing to space content
            out within the larger container.
          </p>
          <p className="lead">
            <Link role="button" className="btn btn-primary btn-lg" to="/">
              Go to Home
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
