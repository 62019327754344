import React, { Component } from "react";
import "../Login/Login.css";
import Axios from "axios";
import BrowserRouter, { Link } from "react-browser-router";

class ForgotPassword extends Component {
  state = { loginButtonTitle: "Submit", email: "" };

  handleEmail = e => {
    this.setState({ email: e.target.value });
  };

  sendEmailToResetPassword = () => {
    console.log("on Submit called");
    this.setState({ loginButtonTitle: "Please wait..." });
    const { email } = this.state;
    if (email == null) {
      return;
    }
    var headers = {
      "Content-Type": "application/json"
    };

    var requestParam = {
      email: email
    };

    return Axios.post(
      "https://roomz-qa-backend.kiwi-internal.com/api/v1/forgot-password/",
      requestParam,
      { headers: headers }
    )
      .then(response => {
        let data = response.data;
        // this.props.history.push("/profile", { data });
      })
      .catch(function(error) {
        console.log("Error", error);
      });
  };

  emailInputField = (
    <input
      type="email"
      placeholder="Please enter email"
      value={this.state.email}
      onChange={this.handleEmail}
    />
  );
  render() {
    return (
      <React.Fragment>
        <div className="container" id="container">
          <div className="form-container sign-in-container">
            <div>
              <h3>Forgot Password</h3>
              {this.emailInputField ? (
                <input
                  type="email"
                  placeholder="Please enter email"
                  value={this.state.email}
                  onChange={this.handleEmail}
                />
              ) : (
                <label />
              )}
              <button onClick={this.sendEmailToResetPassword}>
                {this.state.loginButtonTitle}
              </button>
              <p>
                <Link to="/"> back to Login</Link>
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
