import React, { Component } from "react";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "bootstrap/dist/css/bootstrap.css";
import render from "react-dom";
import Login from "./Login/Login";
import Profile from "./Profile/UserProfile";
import NoPage from "./PageNotExist";
import ForgotPassword from "./Components/ForgotPassword";
import SearchProperty from "./SearchProperty/SearchProperty";
import QuantityForm from "./QuantityForm";
import Home from "./Home/Home";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter,
} from "react-browser-router";
import Privacy from "./privacy";

class App extends Component {
  constructor(props) {
    super(props);
    this.detectAndServe();
    this.state = {};
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  detectAndServe = () => {
    let os = this.getMobileOperatingSystem();
    if (os == "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.blintzpizzauserreactapp";
    } else if (os == "iOS") {
      window.location.href =
        "https://apps.apple.com/in/app/blintz-pizza-online-delivery/id1488505574";
    }
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/profile" component={Profile} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/order-form" component={QuantityForm} />
          <Route path="/privacy-policy" component={Privacy} />
          <PrivateRoute path="/search-property" component={SearchProperty} />
          <Route component={NoPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
