import React, { Component } from "react";

class Privacy extends Component {
  state = {};
  render() {
    return (
      <div style={{ flex: 1 }}>
        <div
          style={{
            paddingHorizontal: 16,
            alignItems: "flex-start",
            marginLeft: 40,
          }}
        >
          <h1
            style={{
              fontSize: 26,
              marginTop: 20,
            }}
          >
            {"Term and Condition".toUpperCase()}
          </h1>
        </div>
        <div
          style={{ marginHorizontal: 16, paddingLeft: 40, paddingRight: 40 }}
        >
          <p
            style={{
              fontSize: 18,
              marginVertical: 10,
            }}
          >
            1. General Provisions
          </p>
          <p>
            1.1. This User Agreement (hereinafter - the Agreement) is an
            agreement between the Visitor, the User and the Company, the subject
            of which is the provision by the Company to the Visitor, User of
            access to familiarization with the App.
          </p>

          <p>
            1.2. The Visitor and User are required to fully familiarize
            themselves with this Agreement prior to using the App functionality.
          </p>

          <p>
            1.3. In case of disagreement with the above conditions, the Visitor
            and User should refrain from further use of the App.
          </p>

          <p>
            1.5. The visitor, user relations for the purchase of goods are
            governed by a public offer for the sale of goods according to the
            samples specified on the App.
          </p>

          <p
            style={{
              fontSize: 18,
              marginVertical: 10,
            }}
          >
            2. Terms and definitions
          </p>
          <p>
            2.1. in this User Agreement, unless the conp otherwise indicates,
            the following capitalized terms have the following meanings:
          </p>

          <p>2.1.1. “Company” - Blint Pizza</p>

          <p>
            2.1.2. “Personal data” - personal data that the User provides about
            himself (name (including a incognito); delivery address; phone
            number; email address). Such data as: phone number and e-mail
            address cannot be attributed to the User’s personal data if this
            data is processed anonymously, that is, without reference to the
            personal data of a specific User. Data that is automatically
            transferred in the process of their use using the software installed
            on the User’s device, including the IP address, information from
            cookies, information about the User’s browser (or other program that
            accesses the App), access time, The address of the requested page is
            not personal data.
          </p>

          <p>
            2.1.3. “Visitor” - any competent person who wants to get acquainted
            with the content of the App.
          </p>

          <p>2.1.4. “User” - A visitor who already has a personal account.</p>

          <p>
            2.1.5. “Goods” - products of a network of pizzerias, united as part
            of a franchise network under the trademark “Blintz”, presented on
            the App.
          </p>

          <p>
            2.1.6. “App” - blintzpizza.com , as well as the “Blintz” Mobile
            Application (regardless of platform).
          </p>

          <p>
            2.2. For the purposes of this Agreement, the terms and definitions
            in the singular also apply to terms and definitions in the plural
            and vice versa.
          </p>

          <p>
            2.3. The terms of this Agreement for the App are also valid for the
            Mobile application (regardless of platform).
          </p>

          <p
            style={{
              fontSize: 18,
              marginVertical: 10,
            }}
          >
            3. Subject of the Agreement
          </p>
          <p>
            3.1. The subject of this Agreement is the procedure and rules for
            using the functional (services) of the App; personal data processing
            conditions established by the Company for Visitors and Users of the
            App.
          </p>

          <p
            style={{
              fontSize: 18,
              marginVertical: 10,
            }}
          >
            4. Registration
          </p>
          <p>
            4.1. To register an account on the App, the User should perform the
            following actions:
          </p>

          <p>
            4.1.1. enter the phone number in the national format (+
            91XXXXXXXXXX); the phone number specified by the User during
            registration will be used as the login account;
          </p>

          <p>
            4.1.2. enter the password provided to the User in the form of SMS –
            message to the phone number indicated by him;
          </p>

          <p>
            4.1.3. optionally provide the Company with an email address,
            birthday.
          </p>

          <p>
            4.2. Registration will be considered completed after the User
            correctly enters the password received by him in the SMS message.
          </p>

          <p>
            4.3. The user is prohibited from transferring his account
            information to third parties. In the event that the User transfers
            his username and / or password to a third party, the User is
            responsible for unauthorized actions of a third party, as for his
            own.
          </p>

          <p>
            4.4. The User is responsible for the accuracy and correctness,
            completeness and accuracy of the indication of his personal data
            when registering an account on the App and for the consequences that
            the User may experience if the specified information is incorrectly
            entered.
          </p>

          <p>
            4.5. The User is obliged to immediately notify the Company of any
            case of unauthorized access to the User’s account, as well as of any
            case of violation of the security of his username and password
            (loss, transfer of data to third parties, etc.).
          </p>

          <p>
            4.6. Any actions committed on the App using the username and
            password of the User are considered committed by the relevant User.
          </p>

          <p>
            4.7. The User is responsible for any information that is posted on
            the App through his account.
          </p>

          <p>
            4.8. The Company has the right to delete the User account in case of
            violation by the latter of the provisions of the Agreement.
          </p>

          <p
            style={{
              fontSize: 18,
              marginVertical: 10,
            }}
          >
            5. Terms of use of information posted on the App
          </p>
          <p>
            5.1. The App includes, but is not limited to, the following: ps,
            photographs, graphic images, trademarks and other materials
            protected by law.
          </p>

          <p>
            5.2. The Visitor and / or User undertakes to use the App only for
            legitimate purposes and in ways that do not violate the rights of
            third parties.
          </p>

          <p>
            5.3. The Company is not liable for damage caused to the technology
            of the Visitor and / or User in the event that this occurred as a
            result of clicking on hyperp links posted on the App.
          </p>

          <p>
            5.4. The Company has the right to suspend the provision of services
            to use the App, or to refuse to the User the possibility of using
            certain resources of the App in case the latter violates the
            provisions of the Agreement.
          </p>

          <p
            style={{
              fontSize: 18,
              marginVertical: 10,
            }}
          >
            6. Powers of the Company
          </p>
          <p>
            6.1. The Company reserves the right to make changes to this
            Agreement without any special notice, in connection with which the
            Visitor and / or User undertakes to independently monitor the
            availability of changes to this Agreement. The new version of the
            Agreement comes into force from the moment of its placement, unless
            otherwise provided by the new version of the Agreement.
          </p>

          <p>
            6.2. The Company reserves the right to change the App, including
            changing or adding sections to its structure, changing the design
            and performing other actions aimed at improving the functionality of
            the App.
          </p>

          <p
            style={{
              fontSize: 18,
              marginVertical: 10,
            }}
          >
            7. Terms of processing user personal data
          </p>
          <p>
            7.1. The Company does not verify the accuracy of personal
            information provided by Users. However, the Company assumes that the
            User provides reliable and sufficient personal information and
            maintains this information up to date.
          </p>

          <p>
            7.2. Under the processing of personal data in this Agreement is
            understood: collection of Personal data, their systematization,
            accumulation, storage, destruction.
          </p>

          <p>7.3. Purpose of processing personal data:</p>

          <p>7.3.1. Order Processing;</p>

          <p>7.3.2. Assessment of the quality of service;</p>

          <p>7.3.3. Keeping records of consumers (customers).</p>

          <p>
            7.4. The User confirms that his acceptance of the Agreement
            (checkmark mark “I agree to the processing of personal data on the
            terms of the user agreement”) means the complete consent of the User
            to all its conditions without exception.
          </p>

          <p>
            7.5. The user has the right to change the details of his account on
            the App or to require its removal by calling +91 9891936918, or by
            sending the user a letter by e-mail to blintzpizza@gmail.com .
          </p>

          {/* <p>
                7.6. The User’s consent to the processing of his personal data is
                valid from the date of its receipt by the Company in accordance
                with clause 7.4. Agreement until the day of withdrawal of consent,
                by sending a written request to the address: 220075, Minsk, st.
                Selitsky, d.21B, room 2, room. 56.
              </p> */}

          <p>
            7.7. The Company has the right to transfer the User’s Personal Data
            to third parties, including through cross-border transfer, in the
            following cases:
          </p>

          <p>7.7.1. for use by the User of the App;</p>

          <p>7.7.2. to provide services to the User;</p>

          <p>
            7.7.3. upon sale or other transfer of the business of the Company
            (in whole or in part), while all obligations to comply with the
            terms of the Agreement are transferred to the acquirer;
          </p>

          <p>
            7.7.4. in order to ensure the possibility of protecting the rights
            and legitimate interests of Users, the Company or third parties;
          </p>

          <p>
            7.7.5. in order to deliver goods to the User through the courier
            service of the Company and persons united within the franchise
            network under the single trademark "Blintz".
          </p>

          <p>
            7.8. The Company has the right to appoint a person (operator of the
            Company) responsible for organizing the processing of Personal Data.
          </p>

          <p>
            7.9. The Company takes the necessary and sufficient organizational
            and technical measures to protect Personal Data from unauthorized or
            accidental access, destruction, alteration, blocking, copying,
            distribution, as well as from other illegal actions of third parties
            with it.
          </p>

          <p>
            7.10. The Company establishes the following legal and organizational
            measures to protect Personal Information: a person is appointed to
            organize the processing of personal data; published and introduced
            documents defining the company's policy regarding the processing of
            personal data, local acts establishing procedures aimed at
            preventing and detecting violations of the legislation of the India,
            eliminating the consequences of such violations; the company's
            employees directly processing personal data are familiar with the
            provisions of the legislation of the India on working with personal
            data, including the requirements for the protection of personal
            data, documents that determine the company's policy regarding the
            processing of personal data, local acts on the processing of
            personal data.
          </p>

          <p>
            7.11. The Company stores the personal information of Users, ensuring
            their confidentiality and protection from unlawful or accidental
            access by third parties.
          </p>

          <p>
            7.12. The Company undertakes to prevent attempts of unauthorized
            access to the personal data of Users provided to the Company; timely
            detect and nip such attempts.
          </p>

          <p>
            7.13. The Company has the right to block, exclude, delete
            information posted by the User without the consent of the latter, if
            such information does not meet the requirements of the current
            legislation of the India and the provisions of the Agreement.
          </p>
        </div>
      </div>
    );
  }
}

export default Privacy;
