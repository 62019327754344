import React, { Component } from "react";
import "./Login.css";
import BrowserRouter, { Link } from "react-browser-router";
import { Redirect } from "react-router-dom";
import ForgotPassword from "../Components/ForgotPassword";
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";

export default class Login extends Component {
  state = {
    email: "",
    password: "",
    loginButtonTitle: "Sign in",
    isUserLogin: true
  };

  handleEmail = e => {
    this.setState({ email: e.target.value });
  };

  handlePassword = e => {
    this.setState({ password: e.target.value });
  };

  loginStudent = () => {
    console.log("on Submit called");
    this.setState({ loginButtonTitle: "Please wait..." });
    const { email, password } = this.state;
    if (email == null || password == null) {
      return;
    }
    return Axios.post(
      "https://roomz-qa-backend.kiwi-internal.com/api/v1/login/",
      {
        email: email,
        password: password,
        device_type: "web",
        device_id: "A290BC17-B657-4076-B0EE-909B936234BC",
        role_type: "STUDENT"
      }
    )
      .then(response => {
        let data = response.data;
        this.props.history.push("/profile", { data });
      })
      .catch(function(error) {
        console.log("Error", error);
      });
  };

  render() {
    const { onLogin } = this.props;
    console.log(this.props);
    return (
      <React.Fragment>
        <div className="container" id="container">
          <div className="form-container sign-in-container">
            <div class="form-div">
              <h1>Sign in</h1>
              <input
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleEmail}
              />
              <input
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handlePassword}
              />
              <Link to="/forgot-password">Forgot your password?</Link>
              <button class="btn btn-primary" onClick={this.loginStudent}>
                {this.state.loginButtonTitle}
              </button>
              <Link to="/search-property">Skip</Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
